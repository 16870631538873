import React from "react";
import { Link } from "gatsby";
import cx from "classnames";
import emcc from "../images/Executive Coach - Angela Cooney - EMCC -accredited coach.svg"

const Footer = ({ className, ...props }) => {
  className = cx(
    "w-full px-8 py-4 text-white bg-teal-700 flex flex-col md:flex-row justify-between items-start md:items-center clear-both",
    className
  );
  return (
    <>
      <div className={className} {...props}>
        <div className="flex-initial text-xl font-semibold">Angela Cooney<br /> 
        <span className="text-pink-600 ">Executive Coach</span><br />
        <img src={emcc} alt="Angela Cooney - EMCC certified Executive Coach - Senior Practitioner - Dublin " />
        </div>
        <div>
          <ul className="flex flex-col md:flex-row text-sm -mx-3 font-medium">
            <li className="mx-3 whitespace-no-wrap"><Link to="/">Home</Link></li>
            <li className="mx-3 whitespace-no-wrap "><Link to="/about">About Me</Link></li>
            <li className="mx-3 whitespace-no-wrap"><Link to="/services">Services</Link></li>
            <li className="mx-3 whitespace-no-wrap"><Link to="/coaching">Coaching</Link></li>
            <li className="mx-3 whitespace-no-wrap"><Link to="/contact">Get In Contact</Link></li>
            <li className="mx-3 mt-1"> ©2021 AC Executive Coach</li>
          </ul>
        </div>
      </div>

      <div className="w-full px-8 py-2 text-white text-xs font-light bg-teal-900 flex flex-col sm:flex-row justify-between items-start md:items-center">
        <div>
          <strong className="sm:ml-0 ml-3 font-bold">Email: </strong>
          <a href="mailto:angela@acexecutivecoach.com" className="hover:underline">angela@acexecutivecoach.com</a>
        </div>
        <div>
          <Link to="/" 
             className="hover:underline">
            ACExecutiveCoach.com
          </Link>
        </div>
      </div>
    </>
  );
};

export default Footer;

